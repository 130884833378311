// import { getMyAbility } from "@/libs/acl/utils";
import moment from "moment";
// import socket from "socket.io-client";
import service from "@/service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  component: {
    ToastificationContent,
  },
  computed: {
    appName() {
      return process.env.VUE_APP_NAME
    },
    appShortName() {
      return process.env.VUE_APP_SHORT_NAME
    },
    apiFile() {
      return process.env.VUE_APP_API_FILE || "https://file.elp-dev.my.id";
    },
    isGM() {
      const SPV_KEYS = ["general manager", "gm", "manager", "general_manager"];
      if (this.user && this.user.level) {
        const levelKey = this.user.level.nama_level
          .split(" ")
          .map((word) => word.toLowerCase())
          .join("_");
        return SPV_KEYS.includes(levelKey);
      }

      return false;
    },
    isOwner() {
      const OWNER_KEY = ["owner"];
      if (this.user && this.user.level) {
        const levelKey = this.user.level.nama_level
          .split(" ")
          .map((word) => word.toLowerCase())
          .join("_");
        return OWNER_KEY.includes(levelKey);
      }

      return false;
    },
    isFinance() {
      const SPV_KEYS = ["finance"];
      if (this.user && this.user.level) {
        const levelKey = this.user.level.nama_level
          .split(" ")
          .map((word) => word.toLowerCase())
          .join("_");
        return SPV_KEYS.includes(levelKey);
      }

      return false;
    },
    isSPV() {
      const SPV_KEYS = ["spv", "supervisor"];
      if (this.user && this.user.level) {
        const levelKey = this.user.level.nama_level
          .split(" ")
          .map((word) => word.toLowerCase())
          .join("_");
        return SPV_KEYS.includes(levelKey);
      }

      return false;
    },
    isSales() {
      const SALES_KEY = "sales";
      if (this.user && this.user.level) {
        return this.user.level.nama_level.toLowerCase() === SALES_KEY;
      }
      return false;
    },
    isAdminAsset() {
      const KEYS = ["admin_asset", "admin_aset"];
      if (this.user && this.user.level) {
        const levelKey = this.user.level.nama_level
          .split(" ")
          .map((word) => word.toLowerCase())
          .join("_");
        return KEYS.includes(levelKey);
      }
      return false;
    },
    isAdminGudang() {
      if (this.user && this.user.level) {
        const levelKey = this.user.level.nama_level
          .split(" ")
          .map((word) => word.toLowerCase())
          .join("_");
        return this.GUDANG_KEYS.includes(levelKey);
      }

      return false;
    },
    myGudang() {
      if (localStorage.myGudang) {
        return JSON.parse(localStorage.myGudang);
      }

      return null;
    },
    gudangName() {
      if (localStorage.myGudang) {
        const gudang = JSON.parse(localStorage.myGudang);
        this.gudang_id = gudang.id;
        return gudang.nama_gudang;
      }

      return null;
    },
    GUDANG_KEYS() {
      return ["admin_gudang", "gudang", "sales", "admin_asset", "admin_aset"];
    },
    user() {
      if (localStorage.userData) {
        return JSON.parse(localStorage.userData);
      }

      return null;
    },
    aksesMenu() {
      const menus = JSON.parse(localStorage.accessMenu);
      let submenus = [];
      menus.map((menu) => {
        if (menu.submenus[0] != null) {
          const mySubmenus = menu.submenus.map((submenu) => ({
            ...submenu,
            create: menu.create,
            update: menu.update,
            delete: menu.delete,
          }));
          submenus.push(...mySubmenus);
        }
      });

      var menu = menus.find((item) => {
        return item.url == this.$route.path;
      });

      var subMenu = submenus.find((data) => {
        return data.url == this.$route.path;
      });

      if (subMenu != undefined) {
        var aksesMenu = subMenu;
        var currentMenu = menus.find(
          (itemMenu) =>
            itemMenu.submenus.filter(
              (itemSubmenu) => itemSubmenu.url == aksesMenu.url
            ).length != 0
        );
      } else {
        var aksesMenu = menu;
        var currentMenu = aksesMenu;
      }

      return currentMenu;
    },
  },
  methods: {
    getTypeFile(file_path) {
      const pathArray = file_path.split("/");
      const fileName = pathArray[pathArray.length - 1];
      const ext = fileName.split(".").pop().toLowerCase();

      const isDocument = [
        "pdf",
        "pdfx",
        "doc",
        "docx",
        "csv",
        "xls",
        "xlsx",
      ].includes(ext);
      const isImage = ["jpg", "jpeg", "svg", "gif", "png"].includes(ext);
      const isAudio = ["mp3", "ogg"].includes(ext);
      const isVideo = ["mp4", "avi"].includes(ext);
      if (isDocument) return "document";
      if (isImage) return "image";
      if (isAudio) return "audio";
      return "video";
    },
    async clearExportedFile(filename) {
      try {
        const config = {
          url: "/clear-export",
          method: "get",
          params: {
            filename,
          },
          headers: {
            Author: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data;
          const text = await blob.text();
          const errorJson = JSON.parse(text);

          return Promise.reject(errorJson);
        }
        return Promise.reject(e);
      }
    },
    eventSocket(key, value) {
      // key : ajuan_barang, ajuan_konsumen, ajuan_bop
      // value: {
      //     level : level_user,
      //     data: response_pos
      // }
      const io = socket("http://notif.bimeastindonesia.com", {
        autoConnect: false,
        transports: ["websocket"],
      });
      io.connect();
      io.on("connection", (client) => {
        console.log("Connection On");
        client.on("disconnect", () => console.log("user disconnect"));
      });

      io.emit(key, value);
    },
    capitalize(s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    },
    async customRejectPromise(e) {
      if (e.response && e.response.data) {
        const error = e.response.data;
        if (typeof error === "object") {
          return Promise.reject(error);
        }

        const blob = e.response.data;
        const text = await blob.text();
        const errorJson = JSON.parse(text);

        return Promise.reject(errorJson);
      }

      return Promise.reject(e);
    },
    humanDateShort(dateString) {
      const date = new Date(dateString);
      return moment(date).locale("id").format("Do-MM-YYYY");
      // return moment(date).locale('id').format('DD-MM-YYYY')
    },
    humanDate(dateString, short = false) {
      const date = new Date(dateString);
      if (short) {
        return moment(date).locale("id").format("ll");
      }
      return moment(date).locale("id").format("Do MMMM YYYY");
      // return moment(date).locale('id').format('DD-MM-YYYY')
    },
    humanDateTime(dateString) {
      const date = new Date(dateString);
      return moment(date).locale("id").format("LLL");
    },
    getTanggal(tanggal) {
      const monthNames = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      let date = new Date(tanggal);
      const timestamp = Date.parse(date);
      const invalidDate = isNaN(timestamp);
      if (invalidDate) {
        return "-";
      }
      let day = date.getDate();
      let month = date.getMonth();
      let year = date.getFullYear();
      let hour = date.getHours();
      let minute = date.getMinutes();
      let cekMinute = date.getMinutes() < 10 ? "0" : "";

      return (
        day +
        " " +
        monthNames[month].substring(0, 3) +
        " " +
        year +
        ", " +
        hour +
        ":" +
        cekMinute +
        minute
      );
    },
    decodeEntities(text) {
      const span = document.createElement("span");

      return text.replace(/&[#A-Za-z0-9]+;/gi, (entity, position, text) => {
        span.innerHTML = entity;
        return span.innerText;
      });
    },
    removeTags(str) {
      const invalidHtmlText = str === null || str === "";
      if (invalidHtmlText) {
        return false;
      }

      // Regular expression to identify HTML tags in
      // the input string. Replacing the identified
      // HTML tag with a null string.
      // ( < ([^>]+) > )
      let newStr = str.replace(/(<([^>]+)>)/gi, "");
      return newStr.replace(/(&([a-zA-Z]+);)/gi, " ");
    },
    async currentMenu(path) {
      const currentRoute = path || this.$route.path;
      const accessMenu = JSON.parse(localStorage.accessMenu);

      let currentMenu = accessMenu.find((menu) => {
        if (menu.children) {
          return menu.children.find((child) => child.url == currentRoute);
        }
        return menu.url == currentRoute;
      });

      if (currentMenu && currentMenu.children) {
        currentMenu = currentMenu.children.find(
          (menu) => menu.url == currentRoute
        );
      }

      const menus = await this.$store.dispatch("menu/getData");
      if (!menus) {
        return null;
      }
      if (!currentMenu) {
        const menus = await this.$store.dispatch("menu/getData", {
          search: path,
        });
        currentMenu = menus.find((menu) => menu.url == path);
      }
      const thereMenu = menus.find((menu) => menu.url == currentRoute);
      if (!thereMenu) return null;
      currentMenu.id = thereMenu.id;
      return currentMenu;
    },
    getShortName(words) {
      return words
        .split(" ")
        .map((word) => word.toLowerCase())
        .join("_");
    },
    unFormatRupiah(angka) {
      if (typeof angka === "number") {
        return angka;
      }
      if (angka) {
        return parseInt(angka.split(".").join(""));
      }

      return "";
    },
    formatRupiah(angka, prefix = undefined) {
      let isMinus = "";
      if (parseInt(angka) < 0) {
        isMinus = "-";
      }
      if (angka) {
        const number_string = angka
          .toString()
          .replace(/[^,\d]/g, "")
          .toString();
        const split = number_string.split(",");
        const sisa = split[0].length % 3;
        let rupiah = split[0].substr(0, sisa);
        const ribuan = split[0].substr(sisa).match(/\d{3}/gi);
        let separator = "";

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        if (ribuan) {
          separator = sisa ? "." : "";
          rupiah += separator + ribuan.join(".");
        }

        rupiah = split[1] != undefined ? `${rupiah},${split[1]}` : rupiah;
        rupiah = `${isMinus}${rupiah}`;
        return prefix == undefined ? rupiah : rupiah ? `Rp. ${rupiah}` : "";
      }

      return angka;
    },
    formatRupiahAkun(angka, prefix = undefined) {
      let isMinus = false;
      if (parseInt(angka) < 0) {
        isMinus = true;
      }
      if (angka) {
        const number_string = angka
          .toString()
          .replace(/[^,\d]/g, "")
          .toString();
        const split = number_string.split(",");
        const sisa = split[0].length % 3;
        let rupiah = split[0].substr(0, sisa);
        const ribuan = split[0].substr(sisa).match(/\d{3}/gi);
        let separator = "";

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        if (ribuan) {
          separator = sisa ? "." : "";
          rupiah += separator + ribuan.join(".");
        }

        rupiah = split[1] != undefined ? `${rupiah},${split[1]}` : rupiah;
        if (isMinus) {
          rupiah = `(${rupiah})`;
        }
        return prefix == undefined ? rupiah : rupiah ? `Rp. ${rupiah}` : "";
      }

      return angka;
    },
    // updateAbility(userRoles) {
    //   const myAbilities = getMyAbility(userRoles)
    //   this.$ability.update(myAbilities)
    // },
    allowCreate(aksesMenu) {
      if (!aksesMenu) aksesMenu = this.aksesMenu;
      if (aksesMenu && aksesMenu.create == 1) {
        return true;
      } else {
        return false;
      }
      // return this.$can("create", aksesMenu);
    },
    allowUpdate(aksesMenu) {
      if (!aksesMenu) aksesMenu = this.aksesMenu;
      if (aksesMenu && aksesMenu.update == 1) {
        return true;
      } else {
        return false;
      }
      // return this.$can("update", aksesMenu);
    },
    allowDelete(aksesMenu) {
      if (!aksesMenu) aksesMenu = this.aksesMenu;
      if (aksesMenu && aksesMenu.delete == 1) {
        return true;
      } else {
        return false;
      }
      // return this.$can("delete", aksesMenu);
    },
    truncateString(str, num) {
      if (str) {
        if (str.length && str.length <= num) {
          return str;
        }

        return `${str.slice(0, num)}...`;
      }

      return str;
    },
    displaySuccess(data) {
      if (data) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Berhasil!",
              icon: "CheckIcon",
              variant: "success",
              text: data.text || data.message,
            },
          },
          {
            position: "bottom-right",
          }
        );
      }
    },
    displayInfo(data) {
      if (data) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: data.title,
              icon: "InfoIcon",
              variant: "info",
              text: data.text,
            },
          },
          {
            position: "bottom-right",
          }
        );
      }
    },
    displayWarning(data) {
      if (data) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: data.title,
              icon: "AlertCircleIcon",
              variant: "warning",
              text: data.text,
            },
          },
          {
            position: "bottom-right",
          }
        );
      }
    },
    getCurrentDate() {
      const date = new Date();
      const getdate =
        date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
      const getMonth =
        date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
      return `${date.getFullYear()}-${getMonth}-${getdate}`;
    },
    getBulan() {
      const d = new Date();
      if (d.getMonth() < 10) {
        return `0${d.getMonth() + 1}`;
      }
      return `${d.getMonth() + 1}`;
    },
    getTahun() {
      const d = new Date();
      return `${d.getFullYear()}`;
    },
    displayError(e) {
      console.error(e);
      if (e.response) {
        const error = e.response.data;
        console.error("error", error);
        if (!error.message) {
          Object.keys(error).map((key) => {
            const message =
              typeof error[key] === "object"
                ? error[key].join(", ")
                : error[key];
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Gagal!",
                  icon: "XCircleIcon",
                  variant: "danger",
                  text: message,
                },
              },
              {
                position: "bottom-right",
              }
            );
          });
        } else {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: "XCircleIcon",
                variant: "danger",
                text: error.error,
              },
            },
            {
              position: "bottom-right",
            }
          );
        }
      } else if (e.message) {
        this.$bvToast.toast(e.message, {
          title: "Peringatan",
          variant: "danger",
          solid: true,
          toaster: "b-toaster-bottom-right",
        });
      } else if (typeof e === "object") {
        e.map((message) => {
          this.$bvToast.toast(message, {
            title: "Peringatan",
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-right",
          });
        });
      } else {
        console.error(e);
      }
    },
  },
};
