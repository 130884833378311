import service from "@/service";
import { secretKey } from "@/auth/secretKey";

export default {
  namespaced: true,
  state: {
    namespaced: true,
    datas: [],
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data;
    },
  },
  actions: {
    async getBankVideo({ commit }) {
      try {
        const config = {
          method: "get",
          url: `/bank-soal`,
          headers: {
            secret: secretKey,
          },
        };
        const response = await service.sendRequest(config);
        commit("SET_DATA", response.data.data); 
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
  getters: {
    getVideos(state) {
      return state.datas; // Getter untuk mengambil data dari state
    },
  },
};
