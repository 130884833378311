import service from "@/service";
import axios from "@/libs/axios";
import { secretKey } from "@/auth/secretKey";

// Module Vuex akun
export default {
  namespaced: true,
  state: {
    datas: [],
    token: null,
    selectedCategoryPaket: null,
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data;
    },
    SET_BEARER(state, accessToken) {
      axios.defaults.headers.common["Author"] = `Bearer ${accessToken}`;
    },
    SET_SELECTED_CATEGORY_PAKET(state, category) {
      state.selectedCategoryPaket = category;
    },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const config = {
          url: "/ujian",
          method: "get",
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        // const result = response.data && response.data.data ? response.data.data : []
        // commit('SET_DATA', result)
        // return Promise.resolve(result)
        commit("SET_DATA", response);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexId({}, id) {
      try {
        let config = {
          method: "get",
          url: `/ujian/${id}`,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async index({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/ujian`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexKategoriUjian({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/kategori-ujian`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async save({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/input/ujian",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async subtesPaket({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/subtest-paket`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async subtesPaketWithBobot({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/subtest-with-bobot`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response.data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async storeSubtestPaket({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: `/input/subtest-paket`,
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async subtesKatPaket({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/subcat-paket`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async CUDsubtesKatPaket({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/input/subcat-paket",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
};
