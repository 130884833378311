export default [
  {
    path: "/",
    name: "admin-login",
    component: () => import("@/views/pages/admin/auth/Login.vue"),
    meta: {
      layout: "full",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "forgot-password",
    name: "admin-forgot-password",
    component: () => import("@/views/pages/admin/auth/ForgotPassword.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "reset-password",
    name: "admin-reset-password",
    component: () => import("@/views/pages/admin/auth/ResetPassword.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "dashboard",
    name: "admin-dashboard",
    component: () => import("@/views/pages/admin/dashboard/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Dashboard",
      breadcrumb: [
        {
          text: "Dashboard",
          active: true,
        },
      ],
    },
  },
  {
    path: "pengguna",
    name: "admin-pengguna",
    component: () => import("@/views/pages/admin/pengguna/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Admin",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "pengguna/edit/:id",
    name: "admin-pengguna-edit",
    component: () => import("@/views/pages/admin/pengguna/Edit.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Edit Admin",
      breadcrumb: [
        {
          text: "Admin",
          to: { name: "admin-pengguna" },
        },
        {
          text: "Edit",
          active: true,
        },
      ],
    },
  },
  {
    path: "customer",
    name: "admin-pengguna-customer",
    component: () => import("@/views/pages/admin/customer/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Customer/Student",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "customer/edit/:id",
    name: "admin-pengguna-customer-edit",
    component: () => import("@/views/pages/admin/customer/Edit.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Edit Customer",
      breadcrumb: [
        {
          text: "List",
          to: { name: "admin-pengguna-customer" },
        },
        {
          text: "Edit",
          active: true,
        },
      ],
    },
  },
  {
    path: "payment",
    name: "admin-payment",
    component: () => import("@/views/pages/admin/payment/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Rekening",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "voucher",
    name: "admin-voucher",
    component: () => import("@/views/pages/admin/voucher/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Voucher",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "transaksi",
    name: "admin-transaksi",
    component: () => import("@/views/pages/admin/transaksi/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Daftar Transaksi",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "transaksi/detail/:id",
    name: "admin-transaksi-detail",
    component: () =>
      import("@/views/pages/admin/transaksi/component/Detail.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      // pageTitle: "Detail Transaksi",
      // breadcrumb: [
      //   {
      //     text: "Transaksi",
      //     to: { name: "admin-transaksi" },
      //   },
      //   {
      //     text: "Detail",
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: "transaksi-ebook",
    name: "admin-transaksi-ebook",
    component: () => import("@/views/pages/admin/transaksi-ebook/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Transaksi Ebook",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "transaksi-ebook/detail/:id",
    name: "admin-transaksi-ebook-detail",
    component: () =>
      import("@/views/pages/admin/transaksi-ebook/component/Detail.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      // pageTitle: "Detail Transaksi",
      // breadcrumb: [
      //   {
      //     text: "Transaksi",
      //     to: { name: "admin-transaksi" },
      //   },
      //   {
      //     text: "Detail",
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: "transaksi-snbp",
    name: "admin-transaksi-snbp",
    component: () => import("@/views/pages/admin/transaksi-snbp/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Transaksi snbp",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "transaksi-snbp/detail/:id",
    name: "admin-transaksi-snbp-detail",
    component: () =>
      import("@/views/pages/admin/transaksi-snbp/component/Detail.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      // pageTitle: "Detail Transaksi",
      // breadcrumb: [
      //   {
      //     text: "Transaksi",
      //     to: { name: "admin-transaksi" },
      //   },
      //   {
      //     text: "Detail",
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: "transaksi-manual",
    name: "admin-transaksi-manual",
    component: () => import("@/views/pages/admin/transaksi-manual/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Transaksi Manual Paket",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "transaksi-manual/detail/:id",
    name: "admin-transaksi-manual-detail",
    component: () =>
      import("@/views/pages/admin/transaksi-manual/component/Detail.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      // pageTitle: "Detail Transaksi",
      // breadcrumb: [
      //   {
      //     text: "Transaksi",
      //     to: { name: "admin-transaksi" },
      //   },
      //   {
      //     text: "Detail",
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: "transaksi-ebook-manual",
    name: "admin-transaksi-ebook-manual",
    component: () =>
      import("@/views/pages/admin/transaksi-ebook-manual/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Transaksi Manual Ebook",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "transaksi-ebook-manual/detail/:id",
    name: "admin-transaksi-ebook-manual-detail",
    component: () =>
      import("@/views/pages/admin/transaksi-ebook-manual/component/Detail.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      // pageTitle: "Detail Transaksi",
      // breadcrumb: [
      //   {
      //     text: "Transaksi",
      //     to: { name: "admin-transaksi" },
      //   },
      //   {
      //     text: "Detail",
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: "tryout",
    name: "admin-tryout",
    component: () => import("@/views/pages/admin/tryout/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Tryout",
      breadcrumb: [
        {
          text: "Tryout",
          active: true,
        },
      ],
    },
  },
  {
    path: "bank-soal",
    name: "admin-bank-soal",
    component: () => import("@/views/pages/admin/bank-soal/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Bank Soal",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  
  {
    path: "bank-soal/tambah",
    name: "admin-tambah-bank-soal",
    component: () => import("@/views/pages/admin/bank-soal/CRU.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Bank Soal",
      breadcrumb: [
        {
          text: "List",
          to: { name: "admin-bank-soal" },
        },
        {
          text: "Tambah",
          active: true,
        },
      ],
    },
  },
  {
    path: "bank-soal/pauli/tambah",
    name: "admin-tambah-pauli-bank-soal",
    component: () => import("@/views/pages/admin/bank-soal/CRU-Pauli.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Bank Soal",
      breadcrumb: [
        {
          text: "List",
          to: { name: "admin-bank-soal" },
        },
        {
          text: "Tambah Soal Pauli (1 Kolom)",
          active: true,
        },
      ],
    },
  },

  {
    path: "bank-soal/pauli/edit/:id",
    name: "admin-edit-pauli-bank-soal",
    component: () => import("@/views/pages/admin/bank-soal/Edit-Pauli.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Bank Soal",
      breadcrumb: [
        {
          text: "List",
          to: { name: "admin-bank-soal" },
        },
        {
          text: "Edit Soal Pauli",
          active: true,
        },
      ],
    },
  },
  {
    path: "bank-soal/edit/:id",
    name: "admin-edit-bank-soal",
    component: () => import("@/views/pages/admin/bank-soal/Edit.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Bank Soal",
      breadcrumb: [
        {
          text: "List",
          to: { name: "admin-bank-soal" },
        },
        {
          text: "Edit",
          active: true,
        },
      ],
    },
  },

  {
    path: "bank-video",
    name: "admin-bank-video",
    component: () => import("@/views/pages/admin/bank-video/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Bank Video",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },

  {
    path: "bank-video-add",
    name: "admin-bank-video-add",
    component: () => import("@/views/pages/admin/bank-video/Add.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Bank Video Add",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },

  {
    path: "kategori-paket-tryout",
    name: "admin-kategori-paket-tryout",
    component: () =>
      import("@/views/pages/admin/paket-tryout/kategori/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Kategori Paket Tryout",
      breadcrumb: [
        {
          text: "Kategori Paket Tryout",
          active: true,
        },
      ],
    },
  },
  {
    path: "paket-tryout/detail/soal/:id",
    name: "admin-paket-detail-soal",
    component: () => import("@/views/pages/admin/jadwal/bank-soal/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Mata Pelajaran",
      breadcrumb: [
        // {
        //   text: "List",
        //   to: { name: "admin-jadwal" },
        // },
        {
          text: "Detail Ujian & Buat Soal",
          active: true,
        },
      ],
    },
  },
  {
    path: "paket-tryout/detail/soal/tambah/:id",
    name: "admin-paket-detail-soal-tambah",
    component: () => import("@/views/pages/admin/jadwal/bank-soal/CRU.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Bank Soal",
      breadcrumb: [
        // {
        //   text: "List",
        //   to: { name: "admin-bank-soal" },
        // },
        {
          text: "Tambah Soal",
          active: true,
        },
      ],
    },
  },
  {
    path: "paket-tryout/detail/soal/tambah-pauli/:id",
    name: "admin-paket-detail-soal-tambah-pauli",
    component: () =>
      import("@/views/pages/admin/jadwal/bank-soal/CRU-Pauli.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Bank Soal",
      breadcrumb: [
        {
          text: "Tambah Soal Pauli (1 Kolom)",
          active: true,
        },
      ],
    },
  },
  {
    path: "paket-tryout/detail/soal/edit/:id",
    name: "admin-paket-detail-soal-edit",
    component: () => import("@/views/pages/admin/jadwal/bank-soal/Edit.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Bank Soal",
      breadcrumb: [
        // {
        //   text: "List",
        //   to: { name: "admin-bank-soal" },
        // },
        {
          text: "Edit Soal",
          active: true,
        },
      ],
    },
  },
  {
    path: "paket-tryout/detail/soal/edit-pauli/:id",
    name: "admin-paket-detail-soal-edit-pauli",
    component: () =>
      import("@/views/pages/admin/jadwal/bank-soal/Edit-Pauli.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Bank Soal",
      breadcrumb: [
        // {
        //   text: "List",
        //   to: { name: "admin-bank-soal" },
        // },
        {
          text: "Edit Soal Pauli",
          active: true,
        },
      ],
    },
  },
  {
    path: "paket-tryout",
    name: "admin-paket-tryout",
    component: () => import("@/views/pages/admin/paket-tryout/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Paket Tryout",
      breadcrumb: [
        {
          text: "Paket Tryout",
          active: true,
        },
      ],
    },
  },
  {
    path: "paket-tryout/detail/:id",
    name: "admin-paket-tryout-detail",
    component: () => import("@/views/pages/admin/paket-tryout/Detail.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Detail Paket Tryout",
      breadcrumb: [
        {
          text: "Paket Tryout",
          to: { name: "admin-paket-tryout" },
        },
        {
          text: "Detail",
          active: true,
        },
      ],
    },
  },
  {
    path: "paket-tryout/video",
    name: "admin-paket-tryout-video",
    component: () => import("@/views/pages/admin/paket-tryout/video/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Detail Paket Video Tryout",
      breadcrumb: [
        {
          text: "Paket video Tryout",
          to: { name: "admin-paket-tryout" },
        },
        {
          text: "Detail",
          active: true,
        },
      ],
    },
  },

  {
    path: "universitas",
    name: "admin-universitas",
    component: () => import("@/views/pages/admin/master-universitas/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Universitas",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "universitas/detail/:id",
    name: "admin-universitas-detail",
    component: () =>
      import("@/views/pages/admin/master-universitas/jurusan/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Universitas",
      breadcrumb: [
        {
          text: "List",
          // active: true,
          to: { name: "admin-universitas" },
        },
        {
          text: "Fakultas/Jurusan",
          active: true,
        },
      ],
    },
  },
  {
    path: "sekolah",
    name: "admin-sekolah",
    component: () => import("@/views/pages/admin/master-sekolah/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Sekolah",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "sekolah/detail/:id",
    name: "admin-sekolah-detail",
    component: () =>
      import("@/views/pages/admin/master-sekolah/jurusan/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Sekolah",
      breadcrumb: [
        {
          text: "List",
          // active: true,
          to: { name: "admin-sekolah" },
        },
        {
          text: "Jurusan",
          active: true,
        },
      ],
    },
  },
  {
    path: "ebook",
    name: "admin-ebook",
    component: () => import("@/views/pages/admin/ebook/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Ebook",
      breadcrumb: [
        {
          text: "Ebook",
          active: true,
        },
      ],
    },
  },
  {
    path: "ebook/detail/:id",
    name: "admin-ebook-detail",
    component: () => import("@/views/pages/admin/ebook/Detail.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Detail Ebook",
      breadcrumb: [
        {
          text: "Ebook",
          to: { name: "admin-ebook" },
        },
        {
          text: "Detail",
          active: true,
        },
      ],
    },
  },
  {
    path: "kategori-paket-ebook",
    name: "admin-kategori-paket-ebook",
    component: () => import("@/views/pages/admin/ebook/kategori/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Kategori Paket E-book",
      breadcrumb: [
        {
          text: "Kategori Paket E-book",
          active: true,
        },
      ],
    },
  },
  {
    path: "ujian-admin",
    name: "admin-ujian",
    component: () => import("@/views/pages/admin/ujian/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Ujian",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "ujian-admin/a",
    name: "admin-ujian-tambah",
    component: () => import("@/views/pages/admin/ujian/Tambah.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Ujian",
      breadcrumb: [
        {
          text: "List",
          to: { name: "admin-ujian" },
        },
        {
          text: "Tambah & Pilih Soal",
          active: true,
        },
      ],
    },
  },
  {
    path: "ujian-admin/detail/:id",
    name: "admin-ujian-detail",
    component: () => import("@/views/pages/admin/ujian/Detail.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Ujian",
      breadcrumb: [
        {
          text: "List",
          to: { name: "admin-ujian" },
        },
        {
          text: "Detail & Pilih Soal",
          active: true,
        },
      ],
    },
  },

  {
    path: "jadwal-admin",
    name: "admin-jadwal",
    component: () => import("@/views/pages/admin/jadwal/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Jadwal",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  // {
  //   path: "jadwal-admin/edit/:id",
  //   name: "admin-jadwal",
  //   component: () => import("@/views/pages/admin/jadwal/Edit.vue"),
  //   meta: {
  //     role: "Admin",
  //     authRequired: true,
  //     pageTitle: "Try Out",
  //     breadcrumb: [
  //       {
  //         text: "List",
  //         to: { name: "admin-jadwal" },
  //       },
  //       {
  //         text: "Edit Try Out",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: "jadwal-admin/detail/soal/:id",
    name: "admin-jadwal-detail-soal",
    component: () => import("@/views/pages/admin/jadwal/bank-soal/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Jadwal",
      breadcrumb: [
        // {
        //   text: "List",
        //   to: { name: "admin-jadwal" },
        // },
        {
          text: "Detail Ujian & Buat Soal",
          active: true,
        },
      ],
    },
  },
  {
    path: "jadwal-admin/detail/soal/tambah/:id",
    name: "admin-jadwal-detail-soal-tambah",
    component: () => import("@/views/pages/admin/jadwal/bank-soal/CRU.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Bank Soal",
      breadcrumb: [
        // {
        //   text: "List",
        //   to: { name: "admin-bank-soal" },
        // },
        {
          text: "Tambah Pauli",
          active: true,
        },
      ],
    },
  },
  {
    path: "jadwal-admin/detail/soal/tambah-pauli/:id",
    name: "admin-jadwal-detail-soal-tambah-pauli",
    component: () =>
      import("@/views/pages/admin/jadwal/bank-soal/CRU-Pauli.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Bank Soal",
      breadcrumb: [
        // {
        //   text: "List",
        //   to: { name: "admin-bank-soal" },
        // },
        {
          text: "Tambah Soal Pauli (1 Kolom)",
          active: true,
        },
      ],
    },
  },
  {
    path: "jadwal-admin/detail/soal/edit/:id",
    name: "admin-jadwal-detail-soal-edit",
    component: () => import("@/views/pages/admin/jadwal/bank-soal/Edit.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Bank Soal",
      breadcrumb: [
        // {
        //   text: "List",
        //   to: { name: "admin-bank-soal" },
        // },
        {
          text: "Edit Soal",
          active: true,
        },
      ],
    },
  },
  {
    path: "jadwal-admin/detail/soal/edit-pauli/:id",
    name: "admin-jadwal-detail-soal-edit-pauli",
    component: () =>
      import("@/views/pages/admin/jadwal/bank-soal/Edit-Pauli.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Bank Soal",
      breadcrumb: [
        // {
        //   text: "List",
        //   to: { name: "admin-bank-soal" },
        // },
        {
          text: "Edit Soal Pauli",
          active: true,
        },
      ],
    },
  },
  {
    path: "jadwal-admin/detail/:id",
    name: "admin-jadwal-detail",
    component: () => import("@/views/pages/admin/jadwal/Detail.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Jadwal",
      breadcrumb: [
        {
          text: "List",
          to: { name: "admin-jadwal" },
        },
        {
          text: "Detail Jadwal",
          active: true,
        },
      ],
    },
  },
  {
    path: "jadwal-admin/edit/:id",
    name: "admin-jadwal-edit",
    component: () => import("@/views/pages/admin/jadwal/Edit.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Try Out",
      breadcrumb: [
        {
          text: "List",
          to: { name: "admin-jadwal" },
        },
        {
          text: "Edit Try Out",
          active: true,
        },
      ],
    },
  },
  {
    path: "jadwal-admin/tambah",
    name: "admin-jadwal-tambah",
    component: () => import("@/views/pages/admin/jadwal/Tambah.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Try Out",
      breadcrumb: [
        {
          text: "List",
          to: { name: "admin-jadwal" },
        },
        {
          text: "Buat Try Out",
          active: true,
        },
      ],
    },
  },
  {
    path: "jadwal-admin/report/:id",
    name: "admin-jadwal-report",
    component: () => import("@/views/pages/admin/jadwal/report/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Try Out",
      breadcrumb: [
        {
          text: "List",
          to: { name: "admin-jadwal" },
        },
        {
          text: "Report Try Out",
          active: true,
        },
      ],
    },
  },
  {
    path: "report/:jadwal_id/rasionalisasi/:siswa_id",
    name: "report-rasionalisasi",
    component: () =>
      import("@/views/pages/admin/jadwal/report/rasionalisasi/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Try Out",
      breadcrumb: [
        {
          text: "List",
          to: { name: "admin-jadwal" },
        },
        {
          text: "Report Try Out",
          active: true,
        },
      ],
    },
  },
  {
    path: "kategori-ujian",
    name: "admin-kategori-ujian",
    component: () => import("@/views/pages/admin/kategori-ujian/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Kategori Mata Pelajaran",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "kategori-artikel",
    name: "admin-kategori-artikel",
    component: () => import("@/views/pages/admin/kategori-artikel/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Kategori Mading",
      breadcrumb: [
        {
          text: "Kategori Mading",
          active: true,
        },
      ],
    },
  },
  {
    path: "artikel",
    name: "admin-artikel",
    component: () => import("@/views/pages/admin/artikel/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Mading " + process.env.VUE_APP_SHORT_NAME,
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "halaman",
    name: "admin-halaman",
    component: () => import("@/views/pages/admin/halaman/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Halaman",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "panduan",
    name: "admin-panduan",
    component: () => import("@/views/pages/admin/panduan/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Panduan",
      breadcrumb: [
        {
          text: "Panduan",
          active: true,
        },
      ],
    },
  },
  {
    path: "faq",
    name: "admin-faq",
    component: () => import("@/views/pages/admin/faq/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "FAQ",
      breadcrumb: [
        {
          text: "FAQ",
          active: true,
        },
      ],
    },
  },
  {
    path: "mata-pelajaran",
    name: "admin-mata-pelajaran",
    component: () => import("@/views/pages/admin/mata-pelajaran/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Mata Pelajaran",
      breadcrumb: [
        {
          text: "Mata Pelajaran",
          active: true,
        },
      ],
    },
  },
  {
    path: "rumpun",
    name: "admin-rumpun",
    component: () => import("@/views/pages/admin/rumpun/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Rumpun",
      breadcrumb: [
        {
          text: "Rumpun",
          active: true,
        },
      ],
    },
  },
  {
    path: "banner",
    name: "admin-banner",
    component: () => import("@/views/pages/admin/banner/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Banner & Pop Up",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "snbp",
    name: "admin-snbp",
    component: () => import("@/views/pages/admin/snbp/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "SNBP",
      breadcrumb: [
        {
          text: "SNBP",
          active: true,
        },
      ],
    },
  },
  {
    path: "snbp-detail",
    name: "admin-snbp-detail",
    component: () => import("@/views/pages/admin/snbp/Detail.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "SNBP Detail",
      breadcrumb: [
        {
          text: "SNBP",
          to: "/master-admin/snbp",
        },
        {
          text: "SNBP Detail",
          active: true,
        },
      ],
    },
  },
  {
    path: "snbp-setting",
    name: "admin-snbp-setting",
    component: () => import("@/views/pages/admin/snbp/Setting.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "SNBP ",
      breadcrumb: [
        {
          text: "Setting SNBP",
          active: true,
        },
      ],
    },
  },
  {
    path: "snbp-member",
    name: "admin-snbp-member",
    component: () => import("@/views/pages/admin/snbp/Member.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "SNBP",
      breadcrumb: [
        {
          text: "Member SNBP",
          active: true,
        },
      ],
    },
  },
  {
    path: "snbp-voucher",
    name: "admin-snbp-voucher",
    component: () => import("@/views/pages/admin/snbp/Voucher.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "SNBP ",
      breadcrumb: [
        {
          text: "Voucher SNBP",
          active: true,
        },
      ],
    },
  },
  {
    path: "role-management",
    name: "admin-role-management",
    component: () => import("@/views/pages/admin/role-management/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Role Management",
      breadcrumb: [
        {
          text: "Role Management",
          active: true,
        },
      ],
    },
  },
  {
    path: "level",
    name: "admin-level",
    component: () => import("@/views/pages/admin/level/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Level",
      breadcrumb: [
        {
          text: "Level",
          active: true,
        },
      ],
    },
  },
  {
    path: "submenu/:menu_id",
    name: "admin-submenu",
    component: () => import("@/views/pages/admin/menu/Submenu.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Sub Menu",
      breadcrumb: [
        {
          text: "Menu",
          to: "/master-admin/menu",
        },
        {
          text: "Sub Menu",
          active: true,
        },
      ],
    },
  },
  {
    path: "menu",
    name: "admin-menu",
    component: () => import("@/views/pages/admin/menu/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "Menu",
      breadcrumb: [
        {
          text: "Menu",
          active: true,
        },
      ],
    },
  },
  {
    path: "rasionalisasi/:type",
    name: "page-rasionalisasi",
    component: () => import("@/views/pages/admin/rasionalisasi/Index.vue"),
    meta: {
      role: "Admin",
      authRequired: true,
      pageTitle: "",
      breadcrumb: [
        {
          text: "Rasionalisasi",
          active: true,
        },
      ],
    },
  },
];
