import service from "@/service";
import axios from "@/libs/axios";
import { secretKey } from "@/auth/secretKey";

// Module Vuex akun
export default {
  namespaced: true,
  state: {
    datas: [],
    token: null,
    currentJadwal: null,
    currentPaket: null,
    currentResult: null,
    currentSiswa: null,
  },
  mutations: {
    SET_CURRENT_JADWAL(state, data) {
      state.currentJadwal = data;
    },
    SET_CURRENT_SISWA(state, data) {
      state.currentSiswa = data;
    },
    SET_CURRENT_PAKET(state, data) {
      state.currentPaket = data;
    },
    SET_RASIONALISASI_DATA(state, data) {
      state.currentResult = data;
    },
    SET_DATA(state, data) {
      state.datas = data;
    },
    SET_BEARER(state, accessToken) {
      axios.defaults.headers.common["Author"] = `Bearer ${accessToken}`;
    },
  },
  actions: {
    async index({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/index-rasionalisasi`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexsma({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/bobot-penilaian-pas`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async CUDsma({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/input/bobot-penilaian-pas",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async indexsiswakedinasan({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/jurusan-paket`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async indexkedinasan({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/setting-bobot-subtest-kedinasan`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async CUDkedinasan({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/input/setting-bobot-subtest-kedinasan",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexbintara({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/setting-bobot-subtest-bintara`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async CUDbintara({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/input/setting-bobot-subtest-bintara",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexutbksubcat({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/setting-bobot-subcat`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexutbksubtest({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/setting-bobot-subtest-utbk`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async CUDutbksubcat({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/input/setting-bobot-subcat",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async CUDutbksubtest({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/input/setting-bobot-subtest-utbk",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexMandiri({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/bobot-penilaian-pas`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
};
