import service from "@/service";
import axios from "@/libs/axios";
import { secretKey } from "@/auth/secretKey";

// Module Vuex akun
export default {
  namespaced: true,
  state: {
    datas: [],
    detail: {},
    token: null,
    tryoutTersedia: [],
    tryoutSaya: [],
    selectedRumpun: [],
    selectedMajors: [],
    selectedSchool: [],
  },
  mutations: {
    SET_TRYOUT_TERSEDIA(state, data) {
      state.tryoutTersedia = data;
    },
    SET_TRYOUT_SAYA(state, data) {
      state.tryoutSaya = data;
    },
    SET_SELECTED_SCHOOL(state, data) {
      state.selectedSchool = data;
    },
    SET_DATA(state, data) {
      state.datas = data;
    },
    SET_BEARER(state, accessToken) {
      axios.defaults.headers.common["Author"] = `Bearer ${accessToken}`;
    },
    SET_DETAIL(state, data) {
      state.detail = data;
    },
    SET_SELECTED_RUMPUN(state, data) {
      state.selectedRumpun = data;
    },
    SET_SELECTED_MAJOR(state, data) {
      state.selectedMajors = data;
    },
  },
  actions: {
    async indexMyPaket({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/index-my-paket`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async myPaket({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/my-paket`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async daftarPaket({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/my-paket/register",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
};
