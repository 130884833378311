import Vue from "vue";

// axios
import axios from "axios";
import router from "@/router";
const baseURL = () =>
  process.env.VUE_APP_API_URL || "https://api-ecourse.digma.id";
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: baseURL(),
  // timeout: 1000,
  headers: {
    secret: "ADeuCasb4fDkdakK5IJas3CQ5h0TfrWGbiyHbYHBBIblitnj545GHdnrcdgNS6Q",
  },
});

// change header Authorization
axiosIns.interceptors.request.use(
  function (conf) {
    let headers = conf.headers;
    const thereAuthorHeader =
      headers.authorization ||
      headers.Authorization ||
      headers.author ||
      headers.Author;
    if (headers && thereAuthorHeader) {
      const authorizationHeader =
        headers.authorization || headers.Authorization;
      const authorizationHeader2 = headers.author || headers.Author;
      headers.Authorization = authorizationHeader || authorizationHeader2;
      headers.Author = authorizationHeader || authorizationHeader2;
      conf.headers = headers;
    }

    return conf;
  },
  function (e) {
    return Promise.reject(e);
  }
);

const responseInterceptor = axiosIns.interceptors.response.use(
  function (response) {
    return Promise.resolve(response);
  },
  function (error) {
    if (error.response.status === 401) {
      let isSiswa = true;

      if (localStorage.userData) {
        const user = JSON.parse(localStorage.userData);
        isSiswa = user?.level?.nama_level.toLowerCase() == "siswa";
      }
      const routeLogin = isSiswa ? "/login" : "/master-admin";
      sessionStorage.clear();
      localStorage.clear();
      router.push({ path: routeLogin });
    }
    return Promise.reject(error);
  }
);

Vue.prototype.$http = axiosIns;

export { responseInterceptor };

export default axiosIns;
