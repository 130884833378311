import service from "@/service";
import axios from "@/libs/axios";
import { secretKey } from "@/auth/secretKey";

// Module Vuex akun
export default {
  namespaced: true,
  state: {
    datas: [],
    token: null,
    currentJadwal: null, // untuk save jadwal yang sedang dibuatjika admin langsung tambah mapel
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data;
    },
    SET_BEARER(state, accessToken) {
      axios.defaults.headers.common["Author"] = `Bearer ${accessToken}`;
    },
    SET_CURRENT_JADWAL(state, data) {
      state.currentJadwal = data;
    },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const config = {
          url: "/jadwal-paket",
          method: "get",
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        // const result = response.data && response.data.data ? response.data.data : []
        // commit('SET_DATA', result)
        // return Promise.resolve(result)
        commit("SET_DATA", response);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexId({}, id) {
      try {
        let config = {
          method: "get",
          url: `/jadwal-paket/${id}`,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async getResult({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/result-paket`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async resetResult({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: `/reset-ujian`,
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async rescheduleUjian({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/reschedule-jadwal",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async index({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/jadwal-paket`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexKategoriJadwalpaket({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/kategori-jadwal-paket`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async save({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/input/jadwal-paket",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response.data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async saveujian({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/input/jadwal-paket-ujian",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async savemember({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/input/jadwal-paket-member",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexujian({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/jadwal-paket-ujian`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexmember({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/jadwal-paket-member`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
};
