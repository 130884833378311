import service from "@/service";
import axios from "@/libs/axios";
import { secretKey } from "@/auth/secretKey";

// Module Vuex akun
export default {
  namespaced: true,
  state: {
    datas: [],
    token: null,
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data;
    },
    SET_BEARER(state, accessToken) {
      sessionStorage.setItem("token", accessToken);
      state.token = accessToken;
      axios.defaults.headers.common["Author"] = `Bearer ${accessToken}`;
    },
  },
  actions: {
    async login({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/auth/user-password",
          data: payload,
          headers: {
            secret: secretKey,
          },
        };

        const response = await service.sendRequest(config);
        const thereToken = response.data.data && response.data.token;
        if (!thereToken) {
          return Promise.reject({
            message: "Profile User tidak ditemukan",
          });
        }
        commit("SET_BEARER", response.data.token);

        localStorage.setItem("userData", JSON.stringify(response.data.data));
        localStorage.setItem("token", response.data.token);

        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async register({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/auth/user-register",
          data: payload,
          headers: {
            secret: secretKey,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async verifikasiEmail({ commit }, token) {
      try {
        let config = {
          method: "get",
          url: `/auth-ou/v-my-acc/${token}`,
          headers: {
            secret: secretKey,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
};
