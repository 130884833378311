import service from "@/service";
import axios from "@/libs/axios";
import { secretKey } from "@/auth/secretKey";

// Module Vuex akun
export default {
  namespaced: true,
  state: {
    datas: [],
    detail: {},
    token: null,
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data;
    },
    SET_BEARER(state, accessToken) {
      axios.defaults.headers.common["Author"] = `Bearer ${accessToken}`;
    },
    SET_DETAIL(state, data) {
      state.detail = data;
    },
  },
  actions: {
    async list({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/snbp-list`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async submit({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/submit/snbp",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    // Setting
    async indexSetting({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/snbp-setting`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async createUpdateDeleteSetting({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/input/snbp-setting",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    // Setting
    async indexMember({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/snbp-member`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async createUpdateDeleteMember({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/input/snbp-member",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    // Setting
    async indexRanking({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/ranking-snbp`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexVoucher({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/snbp-voucher`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async createUpdateDeleteVoucher({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/input/snbp-voucher",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    // Checkout
    async checkout({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/checkout-paket",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    // Cek Sudah Bayar
    async memberCek({ commit }, id) {
      try {
        let config = {
          method: "get",
          url: `/snbp-member-cek/${id}`,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    //Reset Member
    async resetMember({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/rm-snbp-membership",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexdescription({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/snbp-description`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async createUpdateDeletedescription({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/input/snbp-description",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
};
