import service from "@/service";
import axios from "@/libs/axios";
import { secretKey } from "@/auth/secretKey";

// Module Vuex akun
export default {
  namespaced: true,
  state: {
    detail: {},
  },
  mutations: {
    SET_DETAIL(state, data) {
      state.detail = data;
    },
  },
};
