import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from "@/auth/utils";
import pages from "./routes/pages";
import course from "./routes/course";
import siswa from "./routes/siswa/index";
import admin from "./routes/admin/index";
import userAdmin from "./routes/user-admin/index";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      redirect: "/home",
    },
    ...pages,
    ...course,
    ...siswa,
    {
      path: "*",
      redirect: "error-404",
    },
    {
      path: "/master-admin",
      component: () => import("@/views/pages/admin/index.vue"),
      children: [...admin],
    },
    {
      path: "/user-admin",
      component: () => import("@/views/pages/user-admin/index.vue"),
      children: [...userAdmin],
    },
  ],
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn() ? true : false;
  const userData = isLoggedIn ? getUserData() : null;

  // Redirect if logged in
  if (to.meta.authRequired && !isLoggedIn) {
    next("/login");
  }
  if (isLoggedIn && userData) {
    var myRole = null;
    let lowerRole = userData.level.nama_level.toLowerCase();
    if (
      lowerRole == "siswa" ||
      lowerRole == "siswa" ||
      lowerRole == "mahasiswa"
    ) {
      myRole = "Siswa";
    } else {
      myRole = "Admin";
    }

    const menus = JSON.parse(localStorage.accessMenu);
    let submenus = [];
    menus.map((menu) => {
      if (menu.submenus[0] != null) {
        submenus = [...menu.submenus];
      }
    });
    var menu = menus.find((item) => {
      return item.url == to.path;
    });

    var subMenu = submenus.find((data) => {
      return data.url == to.path;
    });

    if (subMenu != undefined) {
      var aksesMenu = subMenu;
      var currentMenu = menus.find(
        (itemMenu) =>
          itemMenu.submenus.filter(
            (itemSubmenu) => itemSubmenu.url == aksesMenu.url
          ).length != 0
      );
    } else {
      var aksesMenu = menu;
      var currentMenu = aksesMenu;
    }
  }

  if (
    to.meta.authRequired &&
    isLoggedIn &&
    userData != null &&
    to.meta.role != myRole &&
    currentMenu == undefined
  ) {
    next({ name: "misc-not-authorized" });
  }

  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(userData ? myRole : null));
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
